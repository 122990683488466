import React from "react"
import Layout from "../components/Layout"
import ActionButtons from "../components/ActionButtons"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import SEO from "../components/SEO"

const About = () => {
  return (
    <Layout>
      <SEO title="About" />
      <main className="page">
        <section className="about-page">
          <article>
            <h2>More about me</h2>
            <p>
              Hi, I’m Sophia. I’m a passionate Product Designer at Crazy Egg who
              loves looking at details, learning new things, and thinking
              critically.<br></br>
              <br></br>
              I have experience in: Creating product-driven user-centric UX/UI projects for both web and mobile, user research, user testing, & creating icons and illustrations.
              <br></br>
              Utilizing digital technology, I want to design more positive and meaningful experiences for
              everyone.<br></br>
              <br></br>
              Further, you can find me travelling, sketching, and learning Spanish.
            </p>
            <ActionButtons />
            <h4>Let’s connect!</h4>
            <Link to="/contact" className="btn">
              contact
            </Link>
          </article>
          <StaticImage
            src="../assets/images/sophia_about.jpg"
            alt="picture of sophia"
            className="about-img"
            placeholder="blurred"
          />
        </section>
      </main>
    </Layout>
  )
}

export default About
